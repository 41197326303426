import { fetchWrapper } from '../helpers';
import jwt_decode from "jwt-decode";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const userService = {
    getAll,
    getById,
    getAuthPermissions,
    getByIdSelect,
    create,
    createPerm,
    createCall,
    numberMapping,
    createSMS,
    createAuth,
    createAutth,
    createReport,
    login,
    loginotp,
    unsub,
    edit,
    editPerm,
    editAuth,
    editAutth,
    jwtToken,
    delete: _delete,
    users,
    perms,
    permsById,
    authById,
    autthById,
    generateOTP,
    permission,
    logout,
    addRole
};

function users(url) {
    return fetchWrapper.users(url);
}
function perms() {
    return fetchWrapper.perms(baseUrl);
}

function getAll() {
    return fetchWrapper.get(baseUrl);
}

function jwtToken(url, params) {

    return fetchWrapper.getToken(url, params);
}
function getById(id) {
    return fetchWrapper.getById(baseUrl + `/get/${id}`);
}

function getByIdSelect(id) {
    return fetchWrapper.getByIdSelect(baseUrl + `/getroleperm/${id}`);
}

function getAuthPermissions(id) {
    return fetchWrapper.getAuthPermissions(baseUrl + `/getauthperms/${id}`);
}

function permsById(id) {
    return fetchWrapper.permsById(baseUrl + `/getperm/${id}`);
}
function authById(id) {
    return fetchWrapper.authById(baseUrl + `/getauth/${id}`);
}
function autthById(id) {
    return fetchWrapper.autthById(baseUrl + `/getautth/${id}`);
}

function create(params) {
    return fetchWrapper.create(baseUrl, params);
}

function createPerm(params) {
    return fetchWrapper.createPerm(baseUrl, params);
}
function generateOTP(params) {
    return fetchWrapper.generateOTP(baseUrl, params);
}

function createCall(params) {
    return fetchWrapper.createCall(params);
}


function createSMS(params) {
    return fetchWrapper.createSMS(params);
}

function numberMapping(params) {
    return fetchWrapper.numberMapping(params);
}

function createAuth(params) {
    return fetchWrapper.createAuth(baseUrl, params);
}

function createAutth(params) {
    return fetchWrapper.createAutth(baseUrl, params);
}


function createReport(params) {
    return fetchWrapper.createReport(baseUrl, params);
}


function login(params) {
    return fetchWrapper.login(baseUrl, params);
}

function loginotp(params) {
    return fetchWrapper.loginotpp(baseUrl, params);
}

function unsub(params) {
    return fetchWrapper.unsub(baseUrl, params);
}

function edit(params) {
    return fetchWrapper.edit(baseUrl, params);
}
function editPerm(id, params) {
    return fetchWrapper.editPerm(baseUrl + `/pmedit/${id}`, params);
}
function editAuth(id, params) {
    return fetchWrapper.editAuth(baseUrl + `/authedit/${id}`, params);
}

function editAutth(id, params) {
    return fetchWrapper.editAutth(baseUrl + `/autthedit/${id}`, params);
}
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function permission(permission) {
    var decoded = jwt_decode(localStorage.getItem("token"));
    for (var i = 0; i < decoded.permissions.length; i++) {
        if (decoded.permissions[i] === permission) {
            return true
        }
    }
    return false
}

function logout(formData) {
    return fetchWrapper.logout(formData);
}

function addRole(roleData) {
    return fetchWrapper.addRole(roleData);
}
