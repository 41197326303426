import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import UserLayout from "layouts/User.js";
import AuthLayout from "layouts/Auth.js";

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/csiv2/user" render={(props) => {
                        if (localStorage.getItem('token')) {
                            return <UserLayout {...props} />
                        } else {
                            return <Redirect from="/" to="/csiv2/login" />
                        }
                    }} />
                    <Route path="/csiv2/login" exact render={(props) => <AuthLayout {...props} />} />
                    <Redirect from="/" to="/csiv2/login" />
                </Switch>
            </BrowserRouter>
        )
    }
}

export default App;