import React from "react";
import { alertService, userService } from "../../services";
import jwtDecode from "jwt-decode";
import { toast } from 'react-toastify';

import {
  Card,
  CardHeader,
  Col,
  Button,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Container,
  InputGroup,
  Row,
  Modal,
  ModalBody
} from "reactstrap";

const baseUrl = process.env.REACT_APP_BASE_URL;
toast.configure()

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msisdn: "",
       res: null,
       open: false,
      spinner: false
    }
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleSubmit = event => {
    event.preventDefault();
    const data = {
      msisdn: this.state.msisdn
    }
    this.searchUser(data);
  }
  handleUnsub = event => {
    event.preventDefault();
    const data = {
      msisdn: this.state.msisdn,
    }
    this.onUnSub(data);
  }
   unSub = () => {
      if (userService.permission("UNSUB"))
         return (
            <Button
               onClick={() => this.setState({ open: !this.state.open })}
               className="float-right"
               color="danger"
               size="md"
               disabled={this.state.res ? this.state.res.status === "UnSubscribed" ? true : false : true}
            >
               Unsub
            </Button>
         )
   }
  onUnSub(data) {
    const token = localStorage.getItem('token')
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token,
      },
    };
    fetch(baseUrl + '/unsub/' + data.msisdn, requestOptions).then(data =>
      toast.success("Unsubscription Successful", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }),
      this.setState({ open: !this.state.open }),
      this.handleSubmit()
    )
  }
  searchUser(data) {
    this.setState({ spinner: true })
    const decoded = jwtDecode(localStorage.getItem("token"));
    if (decoded.exp < new Date().getTime())
      return userService
        .numberMapping(data.msisdn)
        .then((res) => {
          if (res.data !== null)
            this.setState({
              res: res.data,
              spinner: false
            })
          else {
            toast.warning("No result found against this MSISDN", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
                  spinner: false ,
                  res: null,
               })

          }
        })
        .catch(alertService.error);
  }
  render() {
    return (
      <>
        <Modal size="md" centered={true} isOpen={this.state.open}>
          <ModalBody>
            <h2>Unsubscribe confirmation</h2>
            <p>Are you sure you want to Unsubscribe</p>
            <div className="float-right">
              <Button
                color="danger"
                size="sm"
                onClick={this.handleUnsub}>
                Ok
              </Button>
              <Button
                color="success"
                size="sm"
                onClick={() => this.setState({ open: !(this.state.open) })}>
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <div className="header bg-gradient-info pb-8 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col sm="7">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="7">
                            <FormGroup className="mb-3">
                              <Label style={{ fontWeight: 'bold' }}>MSISDN (34xxxxxxxx)</Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  name="msisdn"
                                  placeholder="Enter MSISDN (34xxxxxxxx)"
                                  value={this.state.name}
                                  onChange={this.handleChange}
                                  type="text"
                                  maxLength={10}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col style={{ paddingTop: "5.5%", marginLeft: "6%" }}>
                            <Row>
                              <Button
                                onClick={this.handleSubmit}
                                className="float-right"
                                color="success"
                                size="md"
                                type="submit"
                                disabled={this.state.msisdn === "" ? true : this.state.spinner ? true : false}
                              >
                                Submit
                              </Button>
                              {/*<Button*/}
                              {/*  onClick={() => this.setState({ open: !this.state.open })}*/}
                              {/*  className="float-right"*/}
                              {/*  color="danger"*/}
                              {/*  size="md"*/}
                              {/*  disabled={this.state.res ? this.state.res.status === "UnSubscribed" ? true : false : true}*/}
                              {/*>*/}
                              {/*  Unsub*/}
                              {/*</Button>*/}
                               {this.unSub()}
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">User Record</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Virtual MSISDN</th>
                      <th scope="col">Telco MSISDN</th>
                      <th scope="col">Status</th>
                      <th scope="col">Billed Upto</th>
                      <th scope="col">Telco Type</th>
                      <th scope="col">Subscription Date</th>
                      <th scope="col">Unsub Date</th>
                      <th scope="col">Unsub Mode</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.res !== null ?
                      <tr key={this.state.res.id}>
                      {this.state.res.virtualMSISDN !== null ?
                        <td>{this.state.res.virtualMSISDN}</td> : <td>NA</td>}
                      {this.state.res.telcoMSISDN !== null ?
                        <td>{this.state.res.telcoMSISDN}</td> : <td>NA</td>}
                      {this.state.res.status !== null ?
                        <td>{this.state.res.status}</td> : <td>NA</td>}
                      {this.state.res.billedUpto !== null ?
                        <td>{this.state.res.billedUpto}</td> : <td>NA</td>}
                      {this.state.res.telcoType !== null ?
                        <td>{this.state.res.telcoType}</td> : <td>NA</td>}
                      {this.state.res.subscriptionDate !== null ?
                        <td>{this.state.res.subscriptionDate}</td> : <td>NA</td>}
                      {this.state.res.unSubDate !== null ?
                        <td>{this.state.res.unSubDate}</td> : <td>NA</td>}
                      {this.state.res.unSubMode !== null ?
                        <td>{this.state.res.unSubMode}</td> : <td>NA</td>}
                    </tr>
                      :
                      this.state.spinner ?
                        <tr>
                          <td colSpan={9} style={{ textAlign: "center" }}>
                            <div className="spinner-border spinner-border-lg align-center"></div>
                          </td>
                        </tr> :
                        < tr >
                          <td colSpan={9} style={{ textAlign: "center" }}>
                            <div className="p-2">No Result To Display</div>
                          </td>
                        </tr>
                    }
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

export default Home;
